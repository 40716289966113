import { Alert, Box, Button, ButtonDropdown, Form, FormField, Header, Input, SpaceBetween, Spinner, Table, Textarea } from "@cloudscape-design/components";
import { useState } from "react";
import getAuthenticated, { postAuthenticated } from "../request";
import { useErrorContext } from "../ErrorContext";
import { Entitlement, EntitlementsFromJson } from "../interfaces/Entitlement";
import EntitlementsTable from "./FortitudeGrantsTable";
import { useOktaAuth } from '@okta/okta-react';

interface Validation {
    reason?: string;
    amount?: string;
}

export default function GrantFortitude({ contextUsername }: { contextUsername?: string }) {
    const [username, setUsername] = useState( contextUsername || "")
    const [reason, setReason] = useState("")
    const [amount, setAmount] = useState(0)
    const [type, setType] = useState(2)
    const [loading, setLoading] = useState(false)
    const [grants, setGrants] = useState<Entitlement[] | null>(null)
    const [successMessage, setSuccessMessage] = useState<string>("")
    const [validationError, setValidationError] = useState<Validation | null>(null)
    const { oktaAuth:auth } = useOktaAuth()
    const { setError }= useErrorContext()

    return <>
        {successMessage && <Alert type="success" dismissible onDismiss={() => setSuccessMessage("")}>{successMessage}</Alert>}
        <form onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); }}>
            <Form
                actions={<SpaceBetween direction="horizontal" size="s">
                    <Button disabled={username.length === 0} onClick={() => {
                        setLoading(true)
                        getAuthenticated(`/api/entitlements/list`, auth, { params: { username } }).then(res => {
                            if(res) {
                                setGrants(EntitlementsFromJson(res.data))
                                setLoading(false)
                            }
                        }, err => {
                            setError(err?.response?.data)
                            setLoading(false)
                        })
                    }}>Fetch Previous Entitlements</Button>
                    <Button variant="primary" disabled={username?.length === 0 || reason?.length === 0} onClick={() => {
                        setValidationError(null)
                        if(reason.length > 100) {
                            setValidationError({reason: "Reason must be less than 100 characters"})
                            return
                        }
                        if(username.length > 0 && reason.length > 0) {
                            setLoading(true)

                            postAuthenticated(`/api/entitlements/create`, {
                                    username,
                                    quantity: amount,
                                    type,
                                    reason
                                }, auth).then(res => {
                                // setGrants([...grants!, { entitlement_reason: reason, entitlement_quantity: amount, entitlement_name: "Fortitude", entitlement_type: 2, user_id: 0, entitlement_id: 0 }])
                                setLoading(false)
                                setSuccessMessage(`Grant to ${username} Successful`)
                            }, err => {
                                setError(err?.response?.data)
                                setLoading(false)
                            })
                        }
                    }}>Grant entitlement</Button>
                    </SpaceBetween>}
                >
                    <SpaceBetween direction="vertical" size="s">
                        <FormField label="Username">
                            <Input
                                onChange={({ detail }) => setUsername(detail.value)}
                                value={username}
                                placeholder={"Account Username"}
                                autoFocus
                                type="search"
                                disabled={contextUsername != null}
                            />
                        </FormField>
                        <FormField>
                            <ButtonDropdown
                                items={[{
                                        id: `${2}`,
                                        text: "Fortitude Tokens"
                                    },
                                    {
                                        id: `${8}`,
                                        text: "Expanding Horizons Heroic Tokens"
                                    }]}
                                onItemClick={({ detail }) => {
                                    setType(parseInt(detail.id))
                                }}
                            >
                                Entitlement Type: {type === 2 ? "Fortitude Tokens" : "Expanding Horizons Heroic Tokens"}
                            </ButtonDropdown>
                        </FormField>
                        <FormField label="Reason" errorText={validationError?.reason}>
                            <Textarea
                                onChange={({ detail }) => setReason(detail.value)}
                                value={reason}
                                placeholder={"Reason (Shown in claim window)"}
                            />
                        </FormField>
                        <FormField label="Amount" errorText={validationError?.amount}>
                            <Input
                                onChange={({ detail }) => setAmount(parseInt(detail.value))}
                                value={`${amount}`}
                                placeholder={"Amount (if applicable)"}
                                type="number"
                            />
                        </FormField>
                    </SpaceBetween>
            </Form>
        </form>
        { loading 
            ? <Spinner size="large"/>
            : <SpaceBetween direction="vertical" size="l">
                <Header variant="h2">Previous Entitlements</Header>
                {grants != null && <EntitlementsTable grants={grants} loading={loading} />} 
            </SpaceBetween>
        }

    </>
}
