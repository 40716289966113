
export interface Entitlement{
    entitlement_id: number;
    user_id: number;
    entitlement_name: string;
    entitlement_type: number;
    entitlement_quantity: number;
    entitlement_reason: string;
    expiration_date?: Date;
}
export const EntitlementsFromJson = (json: any): Entitlement[] => {
    return json.map((grant: any) => {
            return {
                entitlement_id: grant.entitlement_id,
                user_id: grant.user_id,
                entitlement_name: grant.entitlement_name,
                entitlement_type: grant.entitlement_type,
                entitlement_quantity: grant.entitlement_quantity,
                entitlement_reason: grant.entitlement_reason,
                expiration_date: parseDate(grant.expiration_date)
            }   
    })
}

const parseDate = (date: any) => {
    if(date && date !== "1970-01-01T00:00:00Z") {
        return new Date(date)
    }
    return undefined
}