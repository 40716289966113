import { Box, Button, Form, FormField, Header, Input, Link, Modal, Pagination, SpaceBetween, Spinner, Table } from "@cloudscape-design/components";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { useErrorContext } from "../ErrorContext";
import { Actor, Command } from "../interfaces/Baselining";
import getAuthenticated, { postAuthenticated } from "../request";

export default function BaseliningAdmin() {
    const [actors, setActors] = useState<Actor[]>([])
    const [loading, setLoading] = useState(true)
    const { oktaAuth:auth } = useOktaAuth()
    const { setError }= useErrorContext()
    const [modalVisible, setModalVisible] = useState(false);
    const [forceSyncing, setForceSyncing] = useState(false);
    const [selectedActor, setSelectedActor] = useState<Actor | null>(null);
    const [actorModalVisible, setActorModalVisible] = useState(false);

    const fetch = () => {
        getAuthenticated("/api/baselining/actors", auth).then(res => {
            setActors(res.data)
            setLoading(false)
        }, err => {
            setLoading(false)
            setError(err)
        })
    }
    const forceSync = () => {
        setForceSyncing(true)
        getAuthenticated("/api/baselining/force-sync", auth).then(res => {
            setTimeout(() => setForceSyncing(false), 5000)
        }, err => {
            setForceSyncing(false)
            setError(err)
        })
    }
    const deleteActor = (actor: Actor) => {
        setLoading(true)
        postAuthenticated("/api/baselining/actor/delete", actor, auth).then(res => {
            fetch()
        }, err => {
            setLoading(false)
            setError(err)
        })
    }
    useEffect(fetch, [])

    const viewCommands = (actor: Actor) => {
        setSelectedActor(actor)
        setActorModalVisible(true)
    }

    const actorColumns = [
        // {id: 'id', header: 'ID', cell: (row: Actor) => <div>{row.id}</div>},
        {id: 'email', header: 'Email', cell: (row: Actor) => <div>{row.email}</div>},
        {id: 'name', header: 'In-Game Name', cell: (row: Actor) => <div>{row.inGameName}</div>},
        {id: 'view', header: 'Actions', cell: (row: Actor) => <SpaceBetween direction="horizontal" size="m">
            <Button onClick={e => viewCommands(row)}>View Commands</Button>
            <Button onClick={e => {deleteActor(row)}}>Delete</Button>
        </SpaceBetween>},
    ]


    return <>
        <ModalCreate visible={modalVisible} setVisible={setModalVisible} callback={() => {
            setLoading(true)
            fetch()
        }} />
        <ActorModal actor={selectedActor!} visible={actorModalVisible} setVisible={setActorModalVisible} />
        <Table
            columnDefinitions={actorColumns}
            items={actors}
            loading={loading}
            loadingText="Loading Actors"
            empty={
                <Box textAlign="center" color="inherit">
                    <b>None Found</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                    >
                        No data to display.
                    </Box>
                </Box>
            }
            header={
                <Header
                    variant="h2"
                    actions={<SpaceBetween direction="horizontal" size="m">
                        <Button disabled={forceSyncing} onClick={() => forceSync()}>Force sync</Button>
                        <Button variant="primary" onClick={() => setModalVisible(true)}>Add new</Button>
                    </SpaceBetween>
                    }>Admin actor management</Header>
            }
        />
    </>
}

function ModalCreate({callback, visible, setVisible}: {callback: () => void, visible: boolean, setVisible: (visible: boolean) => void}) {
    const [saving, setSaving] = useState(false);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const { oktaAuth:auth } = useOktaAuth()
    const { setError }= useErrorContext()

    return (
      <Modal
        onDismiss={() => {
            setVisible(saving || false)
            setEmail("")
            setName("")
        }}
        visible={visible}
        closeAriaLabel="Close"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button disabled={!!saving} variant="link">Cancel</Button>
            </SpaceBetween>
          </Box>
        }
        header="Add new actor"
      >
        <form onSubmit={e => {
            e.preventDefault();
            setSaving(true);
            postAuthenticated("/api/baselining/actor", {
                email, inGameName: name
            } as Actor, auth).then(res => {
                setSaving(false);
                setVisible(false);
                callback();
            }, err => {
                setSaving(false);
                setError(err);
            })
        }}>
            <Form actions={[
                <Button formAction="submit" variant="primary">Submit</Button>
            ]}>
                <SpaceBetween direction="vertical" size="l">
                    <FormField label="Email"><Input value={email} onChange={event => setEmail(event.detail.value)} /></FormField>
                    <FormField label="In-Game Name"><Input value={name} onChange={event => setName(event.detail.value)} /></FormField>
                </SpaceBetween>
            </Form>
        </form>
      </Modal>
    );
  }

const buttonStates = ["Show Tickets Only", "Show No Ticket Only", "Show All"]

function ActorModal({actor, visible, setVisible}: {actor: Actor, visible: boolean, setVisible: (visible: boolean) => void}) {
    const [loading, setLoading] = useState(true);
    let [commands, setCommands] = useState<Command[]>([]);
    const [selectedCommands, setSelectedCommands] = useState<Command[]>([])
    const { oktaAuth:auth } = useOktaAuth()
    const { setError }= useErrorContext()
    const [page, setPage] = useState(1)
    const [buttonState, setButtonState] = useState(0)
    const pageSize = 50

    useEffect(() => {
        if (visible) {
            setLoading(true)
            getAuthenticated(`/api/baselining/commands/${actor.inGameName}`, auth).then(res => {
                setCommands(res.data)
                setLoading(false)
            }, err => {
                setLoading(false)
                setError(err)
            })
        }

        //cleanup
        return () => {
            setCommands([])
            setSelectedCommands([])
        }
    }, [visible, actor])

    // filter by button state
    const filteredCommands = buttonState % 3 === 0
        ? commands
        : buttonState % 3 === 1
            ? commands.filter(c => !!c.ticket_id)
            : commands.filter(c => !c.ticket_id)


    const pagination = <Pagination
        currentPageIndex={page}
        pagesCount={Math.ceil(filteredCommands.length / pageSize)}
        onChange={({detail}) => {
            setPage(detail.currentPageIndex)
        }}
    />

    // paginate
    const paginatedCommands = filteredCommands.length > pageSize * page 
        ? filteredCommands.slice((page - 1) * pageSize, page * pageSize) 
        : filteredCommands.slice(Math.max(0, filteredCommands.length - pageSize), filteredCommands.length)

    return (
        <Modal
            onDismiss={() => {
                setVisible(false)
            }}
            visible={visible}
            closeAriaLabel="Close"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => setVisible(false)}>Cancel</Button>
                    </SpaceBetween>
                </Box>
            }
            header="View commands"
            size="max"
        >
            <Table
                columnDefinitions={[
                    {id: 'timestamp', header: 'Timestamp', cell: (row: Command) => <div>{new Date(row.logTimestamp).toLocaleString()}</div>},
                    {id: 'actor', header: 'Actor', cell: (row: Command) => <div>{row.actorName}</div>},
                    {id: 'command', header: 'Command', cell: (row: Command) => <div>{row.command}</div>},
                    {id: 'message', header: 'Message', cell: (row: Command) => <div>{row.message}</div>},
                    {id: 'ticket', header: 'Ticket', cell: (row: Command) => row.ticket_id ? <Link href={`https://swgr.org/support/${row.ticket_id}`} target="_blank">{row.ticket_id}</Link> : <div></div>},
                    {id: 'comment', header: 'Comment', cell: (row: Command) => <div>{row.comment}</div>},
                ]}
                pagination={pagination}
                items={paginatedCommands}
                header={
                    <SpaceBetween direction="horizontal" size="m">
                        <Button onClick={() => setButtonState((buttonState + 1) % 3)}>{buttonStates[buttonState]}</Button>
                        <Button disabled={selectedCommands.length === 0} onClick={() => {
                            const reviewedCommands = selectedCommands.map(c => (
                                {_id: c._id}));
                            postAuthenticated("/api/baselining/commands/review", reviewedCommands, auth).then(res => {
                                setCommands(commands.filter(c => !selectedCommands.includes(c)))
                                setSelectedCommands([])
                            }, err => {
                                setLoading(false)
                                setError(err)
                            })
                        }}>Mark Reviewed</Button>
                    </SpaceBetween>
                }
                loading={loading}
                loadingText="Loading Commands"
                selectionType="multi"
                onSelectionChange={(selection) => {
                    setSelectedCommands(selection.detail.selectedItems)
                }}
                selectedItems={selectedCommands}
                empty={
                    <Box textAlign="center" color="inherit">
                        <b>None Found</b>
                        <Box
                            padding={{ bottom: "s" }}
                            variant="p"
                            color="inherit"
                        >
                            No data to display.
                        </Box>
                    </Box>
                }
            />
        </Modal>
    );
}