import { Header, Table } from "@cloudscape-design/components";
import { Entitlement } from "../interfaces/Entitlement";

export default function EntitlementsTable({grants, loading}: {grants: Entitlement[], loading: boolean}) {
    return <Table
        columnDefinitions={[
            {id: 'id', header: 'ID', cell: (row: Entitlement) => <div>{row.entitlement_id}</div>},
            {id: 'name', header: 'Name', cell: (row: Entitlement) => <div>{row.entitlement_name}</div>},
            {id: 'type', header: 'Type', cell: (row: Entitlement) => <div>{row.entitlement_type}</div>},
            {id: 'reason', header: 'Reason', cell: (row: Entitlement) => <div>{row.entitlement_reason}</div>},
            {id: 'amount', header: 'Amount', cell: (row: Entitlement) => <div>{row.entitlement_quantity}</div>},
            {id: 'expiration', header: 'Expiration', cell: (row: Entitlement) => <div>{row.expiration_date ? row.expiration_date.toDateString() : "(None)"}</div>},
        ]}
        items={(grants || []).sort((a, b) => b.entitlement_id - a.entitlement_id)}
        loadingText="Loading Entitlements..."
        empty="No Previous Entitlements Found"
        loading={loading}
    />
}